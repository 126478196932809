<template>
    <div class="chart-table-page">
        <div class="wid1200">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
        </div>

        <div class="title-chart-wrap">
            <div class="title-chart">
                <h5>图表库</h5>
                <div>
                    <input type="text" placeholder="请输入关键字" v-model="searchKeyword" @change="getKeyData()"/>
                </div>
            </div>
        </div>
        <div class="wid1200 chart-content">
            <div class="left-chart-wrap">
                <ul>
                    <li class="chart-li" v-for="item in chartPictureData" :key="item.id">
                        <h5 class="title-chart" @click="chartDetail(item.id)" v-html="item.title"></h5>
                        <div class="belong com-style">
                            <p v-if="item.articleTitle"><span>所属文章：</span>{{item.articleTitle}}</p>
                            <p v-if="item.bookTitle"><span style="color: #666;">所属书刊：</span>{{item.bookTitle}}</p>
                        </div>
                        <div class="author-date com-style">
                            <p v-if="item.author"><span>作者：</span>{{item.author}}</p>
                            <p v-if="item.releaseDate"><span style="color: #666;">在线时间：</span>{{item.releaseDate}}</p>
                        </div>
                    </li>
                    <!-- <li class="chart-li">
                        <h5 class="title-chart"  @click="chartDetail">清朝消极治台政策与台湾行政区划的设置</h5>
                        <div class="belong com-style">
                            <p><span>所属文章：</span>集体主义的新化身：三房巷与华西</p>
                            <p><span>所属书刊：</span>中国研究（2006年秋季卷总第4期）</p>
                        </div>
                        <div class="author-date com-style">
                            <p><span>作者：</span>张毅</p>
                            <p><span>在线时间：</span>2020-05-23</p>
                        </div>
                    </li>
                    <li class="chart-li">
                        <h5 class="title-chart"  @click="chartDetail">清朝消极治台政策与台湾行政区划的设置</h5>
                        <div class="belong com-style">
                            <p><span>所属文章：</span>集体主义的新化身：三房巷与华西</p>
                            <p><span>所属书刊：</span>中国研究（2006年秋季卷总第4期）</p>
                        </div>
                        <div class="author-date com-style">
                            <p><span>作者：</span>张毅</p>
                            <p><span>在线时间：</span>2020-05-23</p>
                        </div>
                    </li>
                    <li class="chart-li">
                        <h5 class="title-chart"  @click="chartDetail">清朝消极治台政策与台湾行政区划的设置</h5>
                        <div class="belong com-style">
                            <p><span>所属文章：</span>集体主义的新化身：三房巷与华西</p>
                            <p><span>所属书刊：</span>中国研究（2006年秋季卷总第4期）</p>
                        </div>
                        <div class="author-date com-style">
                            <p><span>作者：</span>张毅</p>
                            <p><span>在线时间：</span>2020-05-23</p>
                        </div>
                    </li>
                    <li class="chart-li">
                        <h5 class="title-chart"  @click="chartDetail">清朝消极治台政策与台湾行政区划的设置</h5>
                        <div class="belong com-style">
                            <p><span>所属文章：</span>集体主义的新化身：三房巷与华西</p>
                            <p><span>所属书刊：</span>中国研究（2006年秋季卷总第4期）</p>
                        </div>
                        <div class="author-date com-style">
                            <p><span>作者：</span>张毅</p>
                            <p><span>在线时间：</span>2020-05-23</p>
                        </div>
                    </li> -->

                </ul>

                <div class="pagination-wrap" style="display: flex; justify-content: center;margin-bottom: 22px;">
                    <el-pagination  @current-change="handleCurrentChange"
                        :current-page="pageNum" :page-size="8"
                        layout="total, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>

            </div>
            <div class="right-download-w">
                <div class="right-download-wrap">
                    <div class="com-title">
                        <div>
                            <img class="title-img" src="../assets/img/download.png" alt="" />
                            <h5>下载排行</h5>
                        </div>
                    </div>
                    <ul class="right-download" style="padding-top: 20px;">
                        <!-- <li class="first-download">
                            <div>
                                <img src="../assets/img/book.png" alt="" />
                            </div>
                            <div>
                                <h5 class="right-title" v-if="hotList && hotList[0] && hotList[0].title">
                                    {{hotList[0].title}}
                                </h5>
                                <p>近代史学刊》是华中师范大学中国近代史研究...</p>
                            </div>
                        </li> -->
                        <li class="download-li" v-for="(item, index) in hotList" :key="index" :id="item.id" v-if="index<10" @click="goHot(item)" style="cursor: pointer;">
                            <!-- <img src="../assets/img/lock.png" alt="" /> -->
                            {{item.searchTitle ? item.searchTitle : item.title}}
                        </li>
                        <!-- <li class="download-li">
                            <img src="../assets/img/lock.png" alt="" />珠海海洋生态文化建设思考
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    components: { BreadCrumb },
    data() {
        return {
            pN: '',
            pageName: '图表库',
            searchKeyword: '',
            total: 0,
            chartPictureData: [],
            pageNum: 1,
            hotList: []
        }
    },
    methods: {
        chartDetail (id) {
            this.$router.push({path: '/chartDetail/c', query:{id: id, pN: encodeURI(this.pageName)}})
        },
        goHot (item) {
            this.$router.push({path: '/aD/a', query: {id: item.id, pN: encodeURI(this.pageName)}})
        },
        getHotList(){
            var _this  = this;
            _this.$http.get('/admin/api/article/hot')
                .then(res=>{
                    if(res.data.code == 0){
                        _this.hotList = res.data.data;
                    }
                })
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.getData()
        },
        getKeyData () {
            this.pageNum = 1
            this.getData()
        },
        getData () {
            var _this =this
            this.$http({
                method: 'get',
                url: '/admin/api/library/resource',
                params: {
                    searchKeyword: this.searchKeyword,
                    libraryId: '',
                    resourceTypeList: 'CHART, PICTURE',
                    pageSize: 8,
                    pageNum: this.pageNum
                }
            }).then(res => {
                if (res.data.code == 0) {
                    _this.chartPictureData = res.data.data.rows
                    _this.total = res.data.data.total
                }
            })
        }
    },
    created() {
        this.getData()
        this.getHotList()
    }
}
</script>


<style lang="scss" scoped>
.chart-table-page {
    // background: blue;
    .title-chart-wrap {
        width: 100%;
        height: 230px;
        // background: url(../assets/img/chartbg.png);
        // background-size: 100% 100%;

        // background-image: url(../assets/img/chart.jpg), url(../assets/img/chartbg.png);
        // background-repeat: no-repeat, no-repeat;
        // background-position: 0 0, 0 15px;
        // background-size: 100% 100%, 100% 87px;

        background-image: url(../assets/img/chartbg.png),url(../assets/img/chart.jpg);
        background-repeat: no-repeat, no-repeat;
        background-position: 0 15px, 0 0;
        background-size: 100% 87px, 100% 100%;

        // background-image: url(../assets/img/chart.jpg);
        // background-repeat: no-repeat;
        // background-position: 0 0;
        // background-size: 100% 100%;



        .title-chart {
            h5 {
                height: 44px;
                font-size: 44px;
                font-weight: 400;
                color: #fefefe;
                padding-top: 37px;
                padding-left: 19%;
                // 30px;
            }
            div {
                padding-top: 10px;
                input {
                    width: 667px;
                    height: 56px;
                    background: #ffffff;
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;
                    font-size: 16px;
                    box-sizing: border-box;
                    padding-left: 16px;
                    margin-top: 23px;
                    margin-left: 19%;
                }
            }
        }
    }

    .chart-content {
        display: flex;
        justify-content: space-between;
        .left-chart-wrap {
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 0 20px;
            margin-top: 24px;
            margin-bottom: 34px;
            ul {
                li {
                    border-bottom: 1px solid #e6e6e6;
                    margin-bottom: 9px;
                    h5.title-chart {
                        width: 820px;
                        color: #333333;
                        font-size: 18px;
                        margin: 22px 0 17px;
                        line-height: 18px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                    .com-style {
                        display: flex;
                        p {
                            font-size: 14px;
                            span {
                                font-size: 14px;
                            }
                        }
                        p:first-child {
                            margin-right: 40px;
                            color: #333;
                            span {
                                color: #666666;
                            }
                        }
                    }
                    .belong {
                        margin-bottom: 12px;
                    }
                    .author-date {
                        margin-bottom: 20px;
                    }
                }
                li:last-child {
                    margin-bottom: 22px;
                }
            }
        }

        .right-download-wrap {
            width: 316px;
            padding: 0 16px;
            margin-top: 24px;
            box-sizing: border-box;
            border: 1px solid #f5f5f5;
            box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-title {
                height: 45px;
                line-height: 45px;
                border-bottom: 2px solid #dedede;
                display: flex;
                align-items: center;
                justify-content: space-between;
                div {
                    display: flex;
                    align-items: center;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 11px;
                        margin-left: 3px;
                    }
                    h5 {
                        height: 45px;
                        line-height: 45px;
                        font-size: 24px;
                        color: #000000;
                        border-bottom: 3px solid #bd1a2d;
                    }
                }

                ul {
                    display: flex;
                    li {
                        font-size: 18px;
                        margin-right: 18px;
                        cursor: pointer;
                        line-height: 36px;
                        color: #333;
                    }
                }
                span {
                    font-size: 14px;
                    color: #404040;
                    cursor: pointer;
                    img {
                        width: 4px;
                        height: 8px;
                        margin-left: 10px;
                    }
                }
            }
            ul.right-download {
                .first-download {
                    display: flex;
                    margin-top: 14px;
                    div {
                        img {
                            width: 118px;
                            height: 74px;
                            margin-right: 9px;
                            margin-bottom: 15px;
                        }
                        h5 {
                            width: 155px;
                            font-size: 16px;
                            color: #333333;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 10px;
                        }
                        p {
                            width: 155px;
                            font-size: 14px;
                            color: #666666;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .download-li {
                    width: 282px;
                    box-sizing: border-box;
                    line-height: 17px;
                    margin: 0 auto 18px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    display: inline-block;
                    position: relative;
                    img {
                        width: 19px;
                        height: 19px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
                .download-li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 8px;
                }
            }
        }
    }
}
</style>
